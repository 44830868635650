// React
import { useEffect } from 'react'

// React router dom
import { useHistory } from 'react-router-dom'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import HeadMeta from '../components/HeadMeta'
import SectionHeading from '../components/headings/SectionHeading'
import ActionBanner from '../components/banners/ActionBanner'
import ProjectColumn from '../components/ProjectColumn'

// Images
import MetaImage from '../../src/assets/images/projects/shooper/2-cloudxier-shooper-highlight03.jpg'

function Projects () {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  const history = useHistory()

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <div style={{overflow: 'hidden', paddingTop: smallScreen ? '130px' : '150px'}}>
      <HeadMeta 
       title="Cloudxier - Our Projects"
       description="Projects created by Cloudxier"
       siteName="Cloudxier"
       url="https://www.cloudxier.com/projects/"
       image={MetaImage} />
      <SectionHeading
        title='Projects' 
        subtitle='' />
      <ProjectColumn />
      <ActionBanner
        action={() => history.push('/contact-us')}
        text='Ready to accelerate digital innovation in your business?'
        actionText='Request a quote' />
    </div>
  )

}

export default Projects