import { useState } from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

// Styled components
import styled from 'styled-components'

// Components
import SectionHeading from '../components/headings/SectionHeading'
import SquareButton from '../components/buttons/SquareButton'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../helpers/fontSize'

function FeatureCard() {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isContentVisible, setContentVisible] = useState(false)

  const [content, setContent] = useState([
    {
      title: 'Clear Code Reviews',
      description: "a Good solution is reflected in well-organized programming process, we always prioritize clean codes for performance review and scalable benefits."
    },
    {
      title: 'User Focused Approach',
      description: "We focus on user-first development approach based on data and transform it into user flows, ensuring users can actively engage with the digital products to create smooth experience."
    },
    {
      title: 'Secure and Scalable',
      description: "We implement the maximum security in our development process and build scalable technology environment."
    },
    {
      title: 'Continuous Delivery',
      description: "In order to speed up the process of integration and release in Software Development cycle, we apply CI/CD implementation (Continuous Integration and Continuous Delivery) to ensure that it takes minimum effort for code deployment and simplify the process of working with multiple engineers simultaneously."
    }
  ])

  return (
    <FeatureContainer smallScreen={smallScreen}>
      <Container style={{ padding: smallScreen ? '0 10vw' : 0 }}>
        <SectionHeading
          title="CLOUDXIER'S WAY OF DEVELOPMENT"
          subtitle='We help you achieve your business goals'
          titleColor='white'
          subtitleColor='white' />
        <VisibilitySensor onChange={(isVisible) => setContentVisible(isVisible)} active={!isContentVisible} partialVisibility={true}>
          <ContentContainer smallScreen={smallScreen} isContentVisible={isContentVisible}>
            <Row>
              {
                content.map((item, i) => {
                  return (
                    <Col key={i} md={12 / content.length}>
                      <ContentHeader smallScreen={smallScreen}>
                        {item.title}
                      </ContentHeader>
                      <ContentDescription smallScreen={smallScreen}>
                        {item.description}
                      </ContentDescription>
                    </Col>
                  )
                })
              }
            </Row>
            <ActionButtonContainer smallScreen={smallScreen}>
              <SquareButton
                action={() => window.open('https://wa.me/6281385898240')}
                textColor='#4C39FF'
                hoverTextColor='white'
                text='Consult with us'
                textSize={smallScreen ? fontSize.bodySm : fontSize.bodyLg}
                backgroundColor='white'
                hoverBgColor='#4C39FF'
                width={smallScreen ? '150px' : '200px'} />
            </ActionButtonContainer>
          </ContentContainer>
        </VisibilitySensor>
      </Container>
    </FeatureContainer>
  )

}

export default FeatureCard

const FeatureContainer = styled.div`
  background-image: linear-gradient(to bottom, #472D80 , #131A91);
  padding: ${({ smallScreen }) => smallScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const ContentContainer = styled.div`
  opacity: ${({ isContentVisible }) => isContentVisible ? 1 : 0};
  padding-top: ${({ smallScreen }) => smallScreen ? '30px' : '50px'};
  transition: opacity 1s
`
const ContentHeader = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : fontSize.heading2Lg};
  color: white;
  font-weight: 700;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '10px' : '30px'}
`
const ContentDescription = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : fontSize.bodyLg};
  color: white;
  text-align;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '30px' : '40px'}
`
const ActionButtonContainer = styled.div`
  padding-top: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : fontSize.bodyLg};
  display: flex;
  justify-content: center;
  align-items: center
`