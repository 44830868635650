import { useState } from 'react'

// React router dom
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// Screens
import Home from './Home'
import AboutUs from './AboutUs'
import Expertise from './Expertise'
import Projects from './Projects'
import ProjectDetails from './ProjectDetails'
import Contact from './Contact'
import PrivacyPolicy from './PrivacyPolicy'

// Components
import Header from '../components/Header'
import Footer from '../components/Footer'
import CollapsableMenu from '../components/CollapsableMenu'

// Route change tracker from google analytics
import RouteChangeTracker from '../components/RouteChangeTracker'

function Main() {

  const [isOpenMenu, setOpenMenu] = useState(false)

  function toggleCollapsableMenu(isOpen) {
    setOpenMenu(isOpen)
  }

  return (
    <Router>
      <RouteChangeTracker />
      <Header
        toggleCollapsableMenu={toggleCollapsableMenu}
        isOpenMenu={isOpenMenu} />
      <CollapsableMenu
        list={
          [
            {
              title: "Mobile Application Development",
              path: "/expertise?id=mobile-application"
            },
            {
              title: "Website Development",
              path: "/expertise?id=website-development"
            },
            {
              title: "Digital Product Experience and Design",
              path: "/expertise?id=digital-product"
            },
            {
              title: "Enterprise Software Development",
              path: "/expertise?id=enterprise-software"
            },
            {
              title: "API Creation and Management",
              path: "/expertise?id=api-management"
            },
            {
              title: "Tech Resource Support",
              path: "/expertise?id=tech-resource-support"
            }
          ]
        }
        isOpen={isOpenMenu}
        toggleCollapsableMenu={toggleCollapsableMenu} />
      <Switch>
        <Route path='/about' exact>
          <AboutUs />
        </Route>
        <Route path='/expertise' exact>
          <Expertise />
        </Route>
        <Route path='/projects' exact>
          <Projects />
        </Route>
        <Route path='/projects/details' exact>
          <ProjectDetails />
        </Route>
        <Route path='/contact-us' exact>
          <Contact />
        </Route>
        <Route path='/privacy-policy' exact>
          <PrivacyPolicy />
        </Route>
        <Route path='/' exact>
          <Home />
        </Route>
      </Switch>
      <Footer />
    </Router>
  )

}

export default Main