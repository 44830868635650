import { useState } from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// React router dom
import { Link, useHistory } from 'react-router-dom'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Images
import ArrowRightIcon from '../assets/images/icons/arrow_right_icon_light.png'
import ChevronRightLight from '../assets/images/icons/chevron_right_light.png'

// Background images
import MobileApplicationDevelopment from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-MobileApp.png'
import DigitalProduct from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-Digital Product Experience and Design.png'
import EnterpriseSoftware from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-Enterprise Software Development.png'
import WebsiteDevelopment from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-Website Development.png'
import BrandingGraphic from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-Branding & Graphic Design.png'
import APICreation from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-APICreation&Mgmt.png'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Helpers
import fontSize from '../helpers/fontSize'

function FeatureTile () {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  const history = useHistory()

  const [isVisibleTile, setVisibleTile] = useState(false)

  const [features, setFeatures] = useState([{
    title: 'Mobile Application Development',
    description: 'Build a modern mobile application while maintaining usabillity for your target audience that works across all platforms.',
    backgroundImg: MobileApplicationDevelopment,
    hover: false,
    link: "/expertise?id=mobile-application"
  }, {
    title: 'Digital Product Experience and Design',
    description: 'We help to create bespoke digital product experience from creating user journey to product workflow, turning it into User Interface (UI) and User Experience (UX) that consumers will love.',
    backgroundImg: DigitalProduct,
    hover: false,
    link: "/expertise?id=digital-product"
  }, {
    title: 'Enterprise Software Development',
    description: 'From back-panel Enterprise Resource Planning (ERP) for B2B or B2C, order management dashboard, we got you all covered.',
    backgroundImg: EnterpriseSoftware,
    hover: false,
    link: "/expertise?id=enterprise-software"
  }, {
    title: 'Website Development',
    description: 'Build website that is accessible to anyone and compatible with any devices. Whether you are a corporate, e-commerce company, business professionals, we provide solution that cater your needs.',
    backgroundImg: WebsiteDevelopment,
    hover: false,
    link: "/expertise?id=website-development"
  }, {
    title: 'API Creation and Management',
    description: 'Provide the logic behind your business idea as a service and connect it via an Application Programming Interface (API) with your website or mobile application.',
    backgroundImg: APICreation,
    hover: false,
    link: "/expertise?id=api-management"
  }, {
    title: 'Tech Resource Support',
    description: 'Provide you with dedicated tech resource support to extend your internal devs team without any hassle.',
    backgroundImg: BrandingGraphic,
    hover: false,
    link: "/expertise?id=tech-resource-support"
  }])

  const [gradientBackgrounds, setGradientBackgrounds] = useState([
    {
      from: '#1447D4',
      to: '#340072'
    },
    {
      from: '#5600D8',
      to: '#340072'
    },
    {
      from: '#290DD6',
      to: '#AD0AD6'
    },
    {
      from: '#517FFF',
      to: '#8200BF'
    },
    {
      from: '#EC038B',
      to: '#5600D8'
    },
    {
      from: '#6C0092',
      to: '#EC1379'
    }
  ])

  function tileHoverAnimation ({ hoverIn, index }) {
    let newTiles = [...features]
    if (hoverIn) {
      newTiles[index].hover = true
    } else {
      newTiles[index].hover = false
    }
    setFeatures(newTiles)
  }

  return (
    <Container fluid={smallScreen ? 'true' : false}>
      <div style={{padding: smallScreen ? '30px 0 30px 0' : '50px 0 50px 0'}}>
        <VisibilitySensor onChange={(isVisible) => setVisibleTile(isVisible)} partialVisibility={true} active={!isVisibleTile}>
          <Row noGutters={smallScreen}>
            {
              features.map((feature, i) => (
                <Col md={12} lg={4} key={i}>
                  <FlexContainer smallScreen={smallScreen} isVisibleTile={isVisibleTile}>
                    <Tile
                      gradientBackground={gradientBackgrounds[i]}
                      tileBackground={feature.backgroundImg}
                      isHovered={feature.hover}
                      smallScreen={smallScreen}
                      onMouseEnter={() => tileHoverAnimation({ hoverIn: true, index: i })}
                      onMouseLeave={() => tileHoverAnimation({ hoverIn: false, index: i })}
                    >
                      <TitleOverlay isHovered={feature.hover} smallScreen={smallScreen}>
                        <Description smallScreen={smallScreen}>
                          {feature.description}
                        </Description>
                      </TitleOverlay>
                      <Title smallScreen={smallScreen} isHovered={feature.hover}>
                        {feature.title}
                      </Title>
                      {
                        smallScreen ?
                          <Link to={feature.link} style={{color: 'white'}}>
                            <ChevronRight isHovered={feature.hover} src={ChevronRightLight} />
                          </Link>
                        :
                        <ActionButtonContainer>
                            <ActionButton>
                              <ActionText smallScreen={smallScreen}>
                                <Link to={feature.link} style={{color: 'white'}}>
                                  Learn more
                                </Link>
                              </ActionText>
                              <ArrowIcon 
                                onClick={() => history.push(feature.link)} 
                                src={ArrowRightIcon} 
                                smallScreen={smallScreen} 
                                isHovered={feature.hover} />
                            </ActionButton>
                          </ActionButtonContainer>
                      }
                    </Tile>
                  </FlexContainer>
                </Col>
              ))
            }
          </Row>
        </VisibilitySensor>
      </div>
    </Container>
  )

}

export default FeatureTile

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ isVisibleTile }) => isVisibleTile ? 1 : 0};
  transition: opacity 1s
`
const Tile = styled.div`
  box-shadow: ${({ isHovered }) => isHovered ? '0px 0px 14px 1px #000000' : '0'};
  background-image: ${({ isHovered, tileBackground, gradientBackground }) => isHovered ? `url("${tileBackground}")` : `linear-gradient(to bottom right, ${gradientBackground.from} , ${gradientBackground.to})`};
  background-size: cover;
  width: 100%;
  height: ${({ smallScreen }) => smallScreen ? '200px' : '400px'};
  margin: 10px 0 10px 0;
  padding: 40px 30px;
  transition: box-shadow .3s
`
const Title = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : fontSize.heading2Lg};
  opacity: ${({ isHovered }) => isHovered ? 0 : 1};
  color: white;
  font-weight: 500
`
const Description = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : fontSize.bodyLg};
  color: white
`
const ActionButtonContainer = styled.div`
  position: absolute;
  bottom: 50px
`
const ActionButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ActionText = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? '7.5px' : '15px'};
  color: white;
  margin-right: 20px
`
const ChevronRight = styled.img`
  opacity: ${({ isHovered }) => isHovered ? 1 : 0};
  transition: opacity .5s;
  width: ${fontSize.bodySm};
  height: ${fontSize.bodySm};
  position: absolute;
  right: 15px;
  bottom: 35px;
  cursor: pointer
`
const ArrowIcon = styled.img`
  padding-top: 5px;
  opacity: ${({ isHovered }) => isHovered ? 1 : 0};
  width: ${({ smallScreen }) => smallScreen ? '15px' : '35px'};
  height: auto;
  transition: opacity .5s;
  cursor: pointer
`
const TitleOverlay = styled.div`
  opacity: ${({ isHovered }) => isHovered ? 1 : 0};
  background-color: rgba(0,0,0,0.5);
  top: 10px;
  left: ${({ smallScreen }) => smallScreen ? '0' : '15px'};
  right: ${({ smallScreen }) => smallScreen ? '0' : '15px'};
  bottom: 10px;
  position: absolute;
  transition: ${({ isHovered }) => isHovered ? 'opacity .5s' : 'none'};
  padding: 40px 30px
`