import { useEffect, useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React router dom
import { useHistory } from 'react-router-dom'

// Styled components
import styled from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Images
import ArrowRightIcon from '../assets/images/icons/arrow_right_icon_light.png'

// Helpers
import fontSize from '../helpers/fontSize'

// React-redux
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  projects: state.projects.projects
})

function PostColumn({ projects }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const history = useHistory()

  const [isPostVisible, setPostVisible] = useState([])

  // Listen on post visibility on load
  useEffect(() => {
    let postVisibility = []
    projects.forEach((post, index) => {
      postVisibility.push(false)
    })
    setPostVisible(postVisibility)
  }, [])

  function handlePostVisible(isVisible, index) {
    let newPostVisible = [...isPostVisible]
    newPostVisible[index] = isVisible
    setPostVisible(newPostVisible)
  }

  function checkOddNumber(number) {
    return number % 2
  }

  return (
    <MainContainer smallScreen={smallScreen}>
      {
        projects.map((item, index) => {
          if (index < 5) {
            return (
              <VisibilitySensor key={index} onChange={(isVisible) => handlePostVisible(isVisible, index)} partialVisibility={true} active={!isPostVisible[index]}>
                <Post smallScreen={smallScreen}>
                  {
                    smallScreen ?
                      <ImageContainer smallScreen={smallScreen}>
                        <Image src={item.image} smallScreen={smallScreen} />
                      </ImageContainer>
                      :
                      null
                  }
                  {
                    !smallScreen && checkOddNumber(index) ?
                      <ImageContainer smallScreen={smallScreen}>
                        <Image src={item.image} smallScreen={smallScreen} />
                      </ImageContainer>
                      :
                      null
                  }
                  <Description smallScreen={smallScreen}>
                    <InnerContainer smallScreen={smallScreen} isPostVisible={isPostVisible} postIndex={index}>
                      <Title smallScreen={smallScreen} onClick={() => history.push(`/projects/details?id=${item.id}`)}>
                        {item.title}
                      </Title>
                      <ActionButton>
                        <ActionText smallScreen={smallScreen} onClick={() => history.push(`/projects/details?id=${item.id}`)}>
                          See more details
                        </ActionText>
                        <ArrowIcon src={ArrowRightIcon} smallScreen={smallScreen} onClick={() => history.push(`/projects/details?id=${item.id}`)} />
                      </ActionButton>
                    </InnerContainer>
                  </Description>
                  {
                    smallScreen ?
                      null
                      :
                      !checkOddNumber(index) ?
                        <ImageContainer smallScreen={smallScreen}>
                          <Image src={item.image} smallScreen={smallScreen} />
                        </ImageContainer>
                        :
                        null
                  }
                </Post>
              </VisibilitySensor>
            )
          }
        })
      }
    </MainContainer>
  )

}

export default connect(mapStateToProps, null)(PostColumn)

const MainContainer = styled.div`
  padding-top: ${({ smallScreen }) => smallScreen ? '30px' : '50px'}
`
const Post = styled.div`
  display: ${({ smallScreen }) => smallScreen ? 'block' : 'flex'};
  width: 100%
`
const Description = styled.div`
  background-color: #131A91;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ smallScreen }) => smallScreen ? 'flex-start' : 'center'};
  width: ${({ smallScreen }) => smallScreen ? '100%' : '50%'};
  height: ${({ smallScreen }) => smallScreen ? '200px' : 'auto'};
  padding: ${({ smallScreen }) => smallScreen ? '0 10vw' : '70px 0'};
`
const InnerContainer = styled.div`
  width: ${({ smallScreen }) => smallScreen ? '100%' : '50%'};
  opacity: ${({ isPostVisible, postIndex }) => isPostVisible[postIndex] ? 1 : 0};
  transition: opacity 1s;
`
const Title = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : fontSize.heading2Lg};
  color: white;
  font-weight: 500;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '15px' : '60px'};
  cursor: pointer;
  user-select: none
`
const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const ActionText = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : fontSize.bodyLg};
  color: white;
  margin-right: 20px;
  cursor: pointer;
  user-select: none
`
const ArrowIcon = styled.img`
  width: ${({ smallScreen }) => smallScreen ? '15px' : '30px'};
  height: auto;
  cursor: pointer
`
const ImageContainer = styled.div`
  width: ${({ smallScreen }) => smallScreen ? '100%' : '50%'};
  height: auto
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover
`

