// React
import { useState } from 'react'

// React bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled, { keyframes } from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import SectionHeading from './headings/SectionHeading'
import FounderProfileModal from './modal/founderProfileModal'

// Helpers
import fontSize from '../helpers/fontSize'

// Images
import Albert from '../assets/images/our_team/Cloudxier-ALBERT-Profile.png'
import Meilisa from '../assets/images/our_team/Cloudxier-MEILISA-Profile.png'

// Social Media Images
import LinkedIn from '../assets/images/icons/linkedin_light.png'

function OurFounders() {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [founderTeams, setFounderTeams] = useState([
    {
      imageUrl: Albert,
      name: 'Albert Agung Arditya',
      position: 'Co-Founder & CEO',
      linkedInUrl: 'https://www.linkedin.com/in/albert-agung-arditya/'
    },
    {
      imageUrl: Meilisa,
      name: 'Meilisa',
      position: 'Co-Founder & Chief of Product',
      linkedInUrl: 'https://www.linkedin.com/in/meilisasanjaya/'
    }
  ])

  const [showModal, setShowModal] = useState(false)

  const [founderData, setFounderData] = useState([
    {
      name: 'Albert Agung Arditya',
      position: 'Co-Founder & CEO',
      Education: [
        {
          title: 'Diploma in Project Management',
          description: 'Interlink Technology Australia, 2021'
        },
        {
          title: 'Full-stack Engineering Bootcamp',
          description: 'Hacktiv8 Coding Bootcamp, Indonesia'
        },
        {
          title: 'Faculty of Economics major in Enterpreneurship',
          description: 'Prasetya Mulya University, Indonesia'
        }
      ],
      Experience: [
        '4+ years experience as Head of Technical Support Engineer at MM-Sustainibility Trisakti University',
        'Have specialisation in Mobile App Development, Software Development, Enterprise Custom Software Development, Website Development, Cloud Computing, and API Creation and Management',
        'Former CTO of Shooper App (Retail-tech consumer analytics app)'
      ],
      Specialization: [
        'React JS', 'React Native', 'Jquery', 'Node JS', 'Sequelize', 'Express JS', 'PostgreSQL', 'MongoDB', 'NoSQL', 'MongoDB Atlas', 'Google Cloud Compute Engine', 'Google Cloud Bucket', 'Google Cloud CDN', 'Amazon EC2', 'Amazon S3', 'IT Project Management'
      ]
    },
    {
      name: 'Meilisa',
      position: 'Co-Founder & Chief of Product',
      Education: [
        {
          title: 'Diploma in Project Management',
          description: 'Interlink Technology Australia, 2021'
        },
        {
          title: 'Certified in Digital Product Management',
          description: 'University of Virginia, Darden School of Business, 2021'
        },
        {
          title: 'Diploma in Graphic Design',
          description: 'Nanyang Academy of Fine Arts, Singapore, 2009-2012'
        },
      ],
      Experience: [
        '8+ years experience in Visual Design, UI/UX Design',
        'Creative and Design Team for Sustainibility Report, Danone Indonesia, PT Saratoga Investama Sedaya Tbk, etc..',
        'Event Lead Coordinator for ISTR 10th Asia Pasific Regional Conference and PhD Seminar (Jakarta, 2017)',
        'Creative Manager as CECT & MM-Sustainibility Trisakti University',
        '5+ years experience as Senior Event Lead at CECT Sustainability Awards www.cectsustainabilityawards.com',
      ],
      Specialization: [
        'UI/UX Design', 'Mobile App dan Web Design', 'IT Product Management', 'Project Management', 'Graphic Design'
      ]
    }
  ])

  const [filteredFounderData, setFilteredFounderData] = useState({})

  const [idModalBody, setIdModalBody] = useState('modal-body')
  const [idModalContent, setIdModalContent] = useState('modal-content')
  const [findIdModal, setFindIdModal] = useState('')

  const showPopModalProfile = (props) => {
    setShowModal(true)
    const filteredData = founderData && founderData.filter((data) => data.name === props.name)
    setFilteredFounderData(filteredData[0])
  }

  function closePopModalProfile() {
    setShowModal(false)
    setFilteredFounderData([])
  }

  return (
    <>
      <SectionHeading
        title='The Founders'
        subtitle='' />
      <ContentContainer smallScreen={smallScreen}>
        <Row>
          {
            founderTeams && founderTeams.map((team, index) => {
              return (
                <Col md={6} key={index}>
                  <TeamContainer smallScreen={smallScreen}>
                    <div>
                      <ImageContainer smallScreen={smallScreen}>
                        <Image src={team.imageUrl} smallScreen={smallScreen} className={team.name} onClick={() => showPopModalProfile({ name: team.name })} />
                      </ImageContainer>
                      <Name className={team.name} smallScreen={smallScreen} onClick={() => showPopModalProfile({ name: team.name })}>
                        {team.name}
                      </Name>
                      <Position smallScreen={smallScreen}>
                        {team.position}
                      </Position>
                      <ImageContainer smallScreen={smallScreen}>
                        <LinkedInImage smallScreen={smallScreen} src={LinkedIn} onClick={() => window.open(team.linkedInUrl)} />
                      </ImageContainer>
                    </div>
                  </TeamContainer>
                </Col>
              )
            })
          }
          {
            showModal === true ? (
              <FounderProfileModal
                founderData={filteredFounderData}
                closePopModalProfile={closePopModalProfile}
                idModalBody={idModalBody}
                idModalContent={idModalContent}
                setFindIdModal={setFindIdModal}
              />
            ) : ""
          }
        </Row>
      </ContentContainer>
    </>
  )
}

export default OurFounders


const ContentContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '20px 10vw 20px 10vw' : '26px 5vw 120px 5vw'}
`
const TeamContainer = styled.div`
  width: 100%;
  height: ${({ smallScreen }) => smallScreen ? '250px' : '600px'};
  background-color: #336AFE;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ smallScreen }) => smallScreen ? '10px 0' : '0'}
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '10px' : '30px'};
  margin-top: 30px
`
const Image = styled.img`
  width: ${({ smallScreen }) => smallScreen ? '100px' : '300px'};
  height: ${({ smallScreen }) => smallScreen ? '100px' : '300px'};
  border-radius: 100%;
  object-fit: cover;
  margin-top: ${({ smallScreen }) => smallScreen ? '10px' : '30px'};
  &: hover {
    cursor: ${({ className }) => className === 'Albert Agung Arditya' || className === 'Meilisa' ? 'pointer' : 'auto'}
  }
`
const Name = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : "25px"};
  font-weight: bold;
  text-align: center;
  color: white;
  &: hover {
    cursor: ${({ className }) => className === 'Albert Agung Arditya' || className === 'Meilisa' ? 'pointer' : 'auto'}
  }
`
const Position = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : "25px"};
  text-align: center;
  color: white
`
const LinkedInImage = styled.img`
  width: ${({ smallScreen }) => smallScreen ? '20px' : '40px'};
  height: ${({ smallScreen }) => smallScreen ? '20px' : '40px'};
  border-radius: 100%;
  object-fit: cover;
  margin-top: ${({ smallScreen }) => smallScreen ? '-25px' : '10px'};
  cursor: pointer;
`