// React
import { useState } from 'react'

// React router dom
import { Link } from 'react-router-dom'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled from 'styled-components'

// Helpers
import fontSize from '../helpers/fontSize'

// React-redux
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  projects: state.projects.projects
})

function ProjectColumn ({ projects }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  return (
    <Container style={{
      padding: smallScreen ? '0 10vw 20px 10vw' : '10px 0 50px 0'
    }}>
      <Row>
        {
          projects.map((project, index) => {
            return (
              <Col key={index} md={6}>
                <ContentContainer smallScreen={smallScreen}>
                  <ImageContainer smallScreen={smallScreen}>
                    <Image src={project.image}/>
                  </ImageContainer>
                  <TextContainer>
                    <Category smallScreen={smallScreen}>
                      {project.category}
                    </Category>
                    <Title smallScreen={smallScreen}>
                      <Link to={`/projects/details?id=${project.id}`} style={{color: 'black'}} params={{ project: project }}>
                        {project.title}
                      </Link>
                    </Title>
                  </TextContainer>
                </ContentContainer>
              </Col>
            )
          })
        }
      </Row>
    </Container>
  )

}

export default connect(mapStateToProps, null)(ProjectColumn)

const ContentContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '15px 0' : '30px 0'};
`
const ImageContainer = styled.div`
  width: auto;
  height: ${({ smallScreen }) => smallScreen ? '200px' : '400px'};
  margin-bottom: ${({ smallScreen }) => smallScreen ? '10px' : '20px'};
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Category = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : fontSize.bodyLg};
  text-transform: uppercase;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '5px' : '10px'};
  text-align: center
`
const Title = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading3Sm : fontSize.heading3Lg};
  font-weight: bold;
  text-align: center
`