// React
import { useEffect } from 'react'

// React bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Images
import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'

// Components
import HeadMeta from '../components/HeadMeta'
import ActionBanner from '../components/banners/ActionBanner'
import ContactHeading from '../components/headings/ContactHeading'
import Address from '../components/texts/Address'
import ContactForm from '../components/ContactForm'

function Contact () {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <div style={{overflow: 'hidden', paddingTop: smallScreen ? '130px' : '150px'}}>
      <HeadMeta 
        title='Cloudxier - Contact Us'
        description="Discuss your idea with us, we'd love to hear from you"
        siteName="Cloudxier"
        url='https://www.cloudxier.com/contact-us/'
        image={CloudxierLogoSquare} />
      <Container style={{padding: smallScreen ? '0 10vw 30px 10vw' : '0 0 50px 0'}}>
        <Row>
          <Col md={6} style={{padding: smallScreen ? '0' : '0 100px 0 0'}}>
            <ContactHeading
              title="Contact us"
              subtitle="Discuss your idea with us, we'd love to hear from you."
              titleColor="#131A91" />
            { smallScreen ? <ContactForm /> : <Address /> }
          </Col>
          <Col md={6}>
            { smallScreen ? <Address /> : <ContactForm /> }
          </Col>
        </Row>
      </Container>
      <ActionBanner
        text='Ready to accelerate digital innovation in your business?'
        actionText='Request a quote' />
    </div>
  )

}

export default Contact