// React
import { useState } from 'react'

// React bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled, { keyframes } from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import SectionHeading from './headings/SectionHeading'
import OurFounders from './OurFounders'

// Helpers
import fontSize from '../helpers/fontSize'

// Images
import Handson from '../assets/images/our_team/Cloudxier-HANDSON-Profile-Developer.png'
import Abil from '../assets/images/our_team/Cloudxier-ABIL-Profile-Developer.png'
import Nicolas from '../assets/images/our_team/Cloudxier-NICOLAS-Profile-Developer.png'
import Arif from '../assets/images/our_team/Cloudxier-ARIF-Profile-Developer.png'
import Ian from '../assets/images/our_team/Cloudxier-IAN-Profile-Developer.png'
import Benedictus from '../assets/images/our_team/Cloudxier-BENEDICTUS BILLY-Profile-Content.png'
import Emilianus from '../assets/images/our_team/Cloudxier-EMILIANUS-Profile-Finance.png'
import Ikrimah from '../assets/images/our_team/Cloudxier-IKRIMAH-Profile-Designer.png'

function OurTeam() {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [developerTeams, setDeveloperTeams] = useState([
    {
      imageUrl: Handson,
      name: 'Handson Panggabean',
      position: 'Full-stack Software Engineer'
    },
    {
      imageUrl: Abil,
      name: 'Abil Fathir Majid',
      position: 'Full-stack Software Engineer'
    },
    {
      imageUrl: Arif,
      name: 'Arif Rahman',
      position: 'Back-end Software Engineer'
    },
    {
      imageUrl: Nicolas,
      name: 'Nicolas Gunawan',
      position: 'Front-end & Technical Lead Assistant'
    },
    {
      imageUrl: Benedictus,
      name: 'Benedictus Billy',
      position: 'Content and Communication'
    },
    {
      imageUrl: Emilianus,
      name: 'Emilianus Irawanto',
      position: 'Finance and Legal Assistant'
    },
    {
      imageUrl: Ikrimah,
      name: 'Ikrimah Nurhalimah S',
      position: 'Creative Designer'
    },
    {
      imageUrl: Ian,
      name: 'Ian Winanto',
      position: 'Technical Advisor'
    }
  ])

  return (
    <MainContainer smallScreen={smallScreen}>
      <OurFounders />
      <SectionHeading
        title='Our Team'
        subtitle='' />
      <ContentContainer smallScreen={smallScreen}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {
            developerTeams.map((team, index) => {
              return (
                <Col md={4} key={index}>
                  <DeveloperTeamContainer style={{ backgroundColor: "transparent" }} smallScreen={smallScreen}>
                    <div>
                      <ImageContainer smallScreen={smallScreen} style={{ marginTop: "0px" }}>
                        <Image src={team.imageUrl} smallScreen={smallScreen} />
                      </ImageContainer>
                      <Name smallScreen={smallScreen}>
                        {team.name}
                      </Name>
                      <Position smallScreen={smallScreen}>
                        {team.position}
                      </Position>
                    </div>
                  </DeveloperTeamContainer>
                </Col>
              )
            })
          }
        </Row>
      </ContentContainer>
    </MainContainer>
  )

}

export default OurTeam

const MainContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '30px 0 30px 0' : '0 0 50px 0'}
`
const ContentContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '20px 10vw 20px 10vw' : '26px 5vw 50px 5vw'}
`
const DeveloperTeamContainer = styled.div`
  width: 100%;
  height: ${({ smallScreen }) => smallScreen ? '200px' : '500px'};
  background-color: #336AFE;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ smallScreen }) => smallScreen ? '10px 0' : '0'}
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '10px' : '30px'};
  margin-top: 30px
`
const Image = styled.img`
  width: ${({ smallScreen }) => smallScreen ? '100px' : '300px'};
  height: ${({ smallScreen }) => smallScreen ? '100px' : '300px'};
  border-radius: 100%;
  object-fit: cover;
  margin-top: ${({ smallScreen }) => smallScreen ? '10px' : '30px'}
`
const Name = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : "25px"};
  font-weight: 700;
  text-align: center;
  color: #336AFE;
`
const Position = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : "25px"};
  text-align: center;
  color: #336AFE;
`