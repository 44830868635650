// Styled components
import styled from 'styled-components'

// React router dom
import { useHistory } from 'react-router-dom'

// Components
import SectionHeading from '../components/headings/SectionHeading'
import PostColumn from '../components/PostColumn'
import SquareButton from '../components/buttons/SquareButton'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../helpers/fontSize'

function CaseStudies () {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  const history = useHistory()

  return (
    <MainContainer smallScreen={smallScreen}>
      <HeadingContainer smallScreen={smallScreen}>
        <SectionHeading
          title='Recent Projects' 
          subtitle='See how we have helped businesses in digital transformation' />
      </HeadingContainer>
      <PostColumn />
      <ActionButtonContainer smallScreen={smallScreen}>
        <SquareButton
          action={() => history.push('/projects')}
          text='See other projects'
          textSize={smallScreen ? fontSize.bodySm : fontSize.bodyLg}
          textColor='#131A91'
          hoverTextColor='white'
          backgroundColor='transparent'
          hoverBgColor='#131A91'
          width={smallScreen ? '200px' : '250px'}
          border='1px solid #131A91' />
      </ActionButtonContainer>
    </MainContainer>
  )

}

export default CaseStudies

const MainContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const HeadingContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '0 10vw' : '0'}
`
const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ smallScreen }) => smallScreen ? '30px 0 0 0' : '50px 0 0 0'}
`