// React bootstrap
// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import SectionHeading from '../components/headings/SectionHeading'

// Images
import organisation_structure from '../assets/images/our_holding_group/organisation_structure.jpg'

function OurHoldingGroup() {
    const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

    return (
        <MainContainer smallScreen={smallScreen}>
            <SectionHeading
                title='Our Holding Group'
                subtitle=''
            />
            <ContentContainer smallScreen={smallScreen}>
                <Image src={organisation_structure} alt="organisation_structure" />
            </ContentContainer>
        </MainContainer>
    )

}

export default OurHoldingGroup;

const MainContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '30px 0 30px 0' : '50px 0 50px 0'};
`
const ContentContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '0 8px 0 8px' : '0 206px 0 206px'};
`

const Image = styled.img`
    width: 100%;
`