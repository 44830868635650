import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Styled components
import styled from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Helpers
import fontSize from '../../helpers/fontSize'

function ContactHeading ({ title, subtitle, titleColor, subtitleColor }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  const [isHeadingVisible, setHeadingVisible] = useState(false)

  return (
    <VisibilitySensor onChange={(isVisible) => setHeadingVisible(isVisible)} partialVisibility={true} active={!isHeadingVisible}>
      <HeadingContainer 
        isHeadingVisible={isHeadingVisible}
        smallScreen={smallScreen}>
        <Title smallScreen={smallScreen} titleColor={titleColor}>
          {title}
        </Title>
        <Subtitle smallScreen={smallScreen} subtitleColor={subtitleColor}>
          {subtitle}
        </Subtitle>
      </HeadingContainer>
    </VisibilitySensor>
  )

}

export default ContactHeading

const HeadingContainer = styled.div`
  opacity: ${({ isHeadingVisible }) => isHeadingVisible ? 1 : 0};
  transition: opacity 1s;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '25px' : '50px'}
`

const Title = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : fontSize.heading2Lg};
  color: ${({ titleColor }) => titleColor || 'black'};
  font-weight: bold;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '10px' : '30px'}
`

const Subtitle = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : fontSize.heading2Lg};
  color: ${({ subtitleColor }) => subtitleColor || 'black'};
`