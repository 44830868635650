// React responsive
import { useMediaQuery } from 'react-responsive'

// Bootstrap
import Container from 'react-bootstrap/Container'

// Styled components
import styled from 'styled-components'

// Components
import ClientsLogo from '../components/banners/ClientsLogo'

// Helpers
import fontSize from '../helpers/fontSize'

function HomeClients () {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  return (
    <Container>
      <MainContainer smallScreen={smallScreen}>
        <Heading smallScreen={smallScreen}>
          Trusted by happy clients
        </Heading>
        <ClientsLogo />
      </MainContainer>
    </Container>
  )

}

export default HomeClients

const MainContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '30px 10vw 30px 10vw' : '50px 0'}
`
const Heading = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading3Sm : fontSize.heading3Lg};
  color: black;
  font-weight: 700;
  text-align: center
`