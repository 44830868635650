import { useState } from 'react'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Helpers
import fontSize from '../helpers/fontSize'

function FeatureColumn({
  titleText,
  titleColor,
  descriptionText,
  descriptionIsList,
  descriptionColor,
  isMediaLeft,
  mediaUrl,
  mediaHeight,
  textContainerPadding,
  smFullWidthMedia
}) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isVisibleColumn, setVisibleColumn] = useState(false)

  switch (smallScreen) {
    case true:
      if (smFullWidthMedia) {
        return (
          <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
            <FeatureContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
              <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                <Image src={mediaUrl} />
              </ColContainer>
              <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding} smFullWidthMedia={smFullWidthMedia}>
                <Title
                  textColor={titleColor}
                  smallScreen={smallScreen}>
                  {titleText}
                </Title>
                <Description
                  textColor={descriptionColor}
                  smallScreen={smallScreen}>
                  {
                    descriptionIsList ?
                      <ul>
                        {
                          descriptionText.split(',').map((item, index) => {
                            return (
                              <li key={index}>
                                {item}
                              </li>
                            )
                          })
                        }
                      </ul>
                      :
                      descriptionText
                  }
                </Description>
              </ColContainer>
            </FeatureContainer>
          </VisibilitySensor>
        )
      } else {
        return (
          <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
            <FeatureContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
              <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                <ImageContainer smallScreen={smallScreen} mediaHeight={mediaHeight}>
                  <Image src={mediaUrl} />
                </ImageContainer>
              </ColContainer>
              <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                <Title
                  textColor={titleColor}
                  smallScreen={smallScreen}>
                  {titleText}
                </Title>
                <Description
                  textColor={descriptionColor}
                  smallScreen={smallScreen}>
                  {
                    descriptionIsList ?
                      <ul>
                        {
                          descriptionText.split(',').map((item, index) => {
                            return (
                              <li key={index}>
                                {item}
                              </li>
                            )
                          })
                        }
                      </ul>
                      :
                      descriptionText
                  }
                </Description>
              </ColContainer>
            </FeatureContainer>
          </VisibilitySensor>
        )
      }
    case false:
      if (isMediaLeft) {
        return (
          <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
            <FeatureContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
              <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                <ImageContainer smallScreen={smallScreen} mediaHeight={mediaHeight}>
                  <Image src={mediaUrl} />
                </ImageContainer>
              </ColContainer>
              <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                <Title
                  textColor={titleColor}
                  smallScreen={smallScreen}>
                  {titleText}
                </Title>
                <Description
                  textColor={descriptionColor}
                  smallScreen={smallScreen}>
                  {
                    descriptionIsList ?
                      <ul>
                        {
                          descriptionText.split(',').map((item, index) => {
                            return (
                              <li key={index}>
                                {item}
                              </li>
                            )
                          })
                        }
                      </ul>
                      :
                      descriptionText
                  }
                </Description>
              </ColContainer>
            </FeatureContainer>
          </VisibilitySensor>
        )
      } else {
        return (
          <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
            <FeatureContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
              <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                <Title
                  textColor={titleColor}
                  smallScreen={smallScreen}>
                  {titleText}
                </Title>
                <Description
                  textColor={descriptionColor}
                  smallScreen={smallScreen}>
                  {
                    descriptionIsList ?
                      <ul>
                        {
                          descriptionText.split(',').map((item, index) => {
                            return (
                              <li key={index}>
                                {item}
                              </li>
                            )
                          })
                        }
                      </ul>
                      :
                      descriptionText
                  }
                </Description>
              </ColContainer>
              <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                <ImageContainer smallScreen={smallScreen} mediaHeight={mediaHeight}>
                  <Image src={mediaUrl} />
                </ImageContainer>
              </ColContainer>
            </FeatureContainer>
          </VisibilitySensor>
        )
      }
  }

}

export default FeatureColumn

const FeatureContainer = styled.div`
  display: ${({ smallScreen }) => smallScreen ? 'block' : 'flex'};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  opacity: ${({ isVisibleColumn }) => isVisibleColumn ? 1 : 0};
  transition: opacity 1s
`
const ColContainer = styled.div`
  width: ${({ smallScreen }) => smallScreen ? '100%' : '50%'};
  padding: ${({ textContainerPadding, smFullWidthMedia }) => {
    if (textContainerPadding && !smFullWidthMedia) {
      return textContainerPadding
    } else if (smFullWidthMedia) {
      return '10vw'
    } else {
      return 0
    }
  }}
`
const Title = styled.div`
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading4Sm : fontSize.heading4Lg};
  margin-bottom: ${({ smallScreen }) => smallScreen ? '8px' : '18px'};
  font-weight: 700;
`
const Description = styled.div`
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : fontSize.bodyLg};
`
const ImageContainer = styled.div`
  width: 100%;
  height: ${({ mediaHeight }) => mediaHeight}
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain
`
