// React
import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Styled components
import styled from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Helpers
import fontSize from '../../helpers/fontSize'

function Address() {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isComponentVisible, setComponentVisible] = useState(false)

  return (
    <VisibilitySensor onChange={(isVisible) => setComponentVisible(isVisible)} partialVisibility={true} active={!isComponentVisible}>
      <MainContainer smallScreen={smallScreen} isComponentVisible={isComponentVisible}>
        <Title smallScreen={smallScreen}>
          Our Office
        </Title>
        <Content smallScreen={smallScreen}>
          <b style={{ fontWeight: '600' }}>CLOUDXIER</b> at PT. Cendekia Edukasi Citra Tritunggal HQ <br />
          AKR Tower 16<sup>th</sup> Floor Unit 16A <br />
          Jl Panjang No. 5 Kebon Jeruk <br />
          West Jakarta, 11530
        </Content>
        <ContactDetails smallScreen={smallScreen}>
          <b style={{ fontWeight: '600' }}>Email.</b> sayhello@cloudxier.com <br />
          <b style={{ fontWeight: '600' }}>Phone.</b><br />+6221-80647744<br /> +62 81385898240 <a href="http://wa.me/6281385898240">(Click to Whatsapp)</a>
        </ContactDetails>
      </MainContainer>
    </VisibilitySensor>
  )

}

export default Address

const MainContainer = styled.div`
  padding-top: ${({ smallScreen }) => smallScreen ? '0' : '50px'};
  opacity: ${({ isComponentVisible }) => isComponentVisible ? 1 : 0};
  transition: opacity 1s
`
const Title = styled.div`
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : fontSize.bodyLg};
  color: black;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '7.5px' : '15px'};
`
const Content = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : fontSize.bodyLg};
  color: black;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '10px' : '20px'};
  font-weight: 300
`
const ContactDetails = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : fontSize.bodyLg};
  color: black;
  font-weight: 300
`