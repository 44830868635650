import { useState, useRef } from "react"
import styled from "styled-components"

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'

function FounderProfileModal ({founderData, closePopModalProfile, idModalBody, idModalContent, setFindIdModal}) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})
  const [splitTextDesc, setSplitTextDesc] = useState([])
  const [tempTextDesc, setTempTextDesc] = useState('')

  const modalBodyRef = useRef(null)
  const trigeredCloseModal = (ref) => {
    window.onclick = function(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closePopModalProfile()
      }
    }
  }

  return (
    <ModalBody id={idModalBody} smallScreen={smallScreen} onClick={() => trigeredCloseModal(modalBodyRef)}>
      <ModalContent id={idModalContent} smallScreen={smallScreen} ref={modalBodyRef}>
        <CloseButton onClick={closePopModalProfile}>&times;</CloseButton>
        <Name smallScreen={smallScreen}>{founderData.name}</Name>
        <Position smallScreen={smallScreen}>{founderData.position}</Position>
        <Title smallScreen={smallScreen}>Education</Title>
        { 
          founderData.Education.map((data, index) => {
            return (
              <>
                <List key={index} smallScreen={smallScreen}>
                  <span style={{marginLeft: '-5px'}}>
                  {data && data.title}
                  </span>
                </List>
                <Description key={index} smallScreen={smallScreen}>
                  {data && data.description}
                </Description>
              </>
            )
          })
        }
        <Title smallScreen={smallScreen}>Experience</Title>
        { 
          founderData.Experience.map((data, index) => {
            return (
              <List key={index} smallScreen={smallScreen}>
                <span style={{marginLeft: '-5px'}}>
                  {data}
                </span>
              </List>
            )
          })
        }
        <Title smallScreen={smallScreen}>Specialization</Title>
        { 
          founderData.Specialization.map((data, index) => {
            return (
              <Specialization key={index}>{data + ", "}</Specialization>
            )
          })
        }
      </ModalContent>
    </ModalBody>
  )
}

const ModalBody = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  margin: auto;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.4);
`
const ModalContent = styled.div`
  position: absolute;
  // z-index: 2;
  background-color: white;
  margin: auto;
  padding: ${({ smallScreen }) => smallScreen ?  '30px 20px' : '50px 50px' };
  border: 1px solid #888;
  width: ${({ smallScreen }) => smallScreen ?  '300px' : '50%' };
  justify-content: center;
  border-radius: 20px;
  font-size: ${({smallScreen}) => smallScreen ?  '10px' : '15px' };
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const CloseButton = styled.button`
  color: #aaaaaa;
  float: right;
  font-size: ${({smallScreen}) => smallScreen ?  '10px' : '28px' };
  background: transparent;
  border: 0;
  font-weight: bold;
  line-height: ${({smallScreen}) => smallScreen ?  '3rem' : '1.5rem' };
  margin-top: ${({smallScreen}) => smallScreen ?  '-5px' : '-20px' };
  &: hover {
    cursor: pointer;
    color: black;
  }
`
const Name = styled.p`
  color: #336AFE;
  font-size: ${({smallScreen}) => smallScreen ?  '10px' : '25px' };
  font-weight: bold;
`
const Position = styled.p`
  color: black;
  font-size: ${({smallScreen}) => smallScreen ?  '10px' : '25px' };
  margin-top: -15px;
`
const Title = styled.p`
  font-weight: bold;
  // font-size: ${({smallScreen}) => smallScreen ?  '10px' : '25px' };
  margin-top: ${({smallScreen}) => smallScreen ?  '15px' : '30px' };
  margin-bottom: 5px;
`
const List = styled.li`
  text-align: left;
  white-space: pre-line;
`
const Specialization = styled.span`
  text-align: justify;
  margin-top: ${({smallScreen}) => smallScreen ?  '15px' : '-30px' };
`
const Description = styled.span`
  margin-left: ${({smallScreen}) => smallScreen ?  '9px' : '17px' };
`

export default FounderProfileModal