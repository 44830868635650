// React bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import SectionHeading from '../components/headings/SectionHeading'

// Helpers
import fontSize from '../helpers/fontSize'

// Images
import AboutUsBanner from '../assets/images/about_us_banner/Cloudxier-aboutus-banner.png'

function OurStory() {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  return (
    <MainContainer smallScreen={smallScreen}>
      <SectionHeading
        title='About Us'
        subtitle='' />
      <ContentContainer smallScreen={smallScreen}>
        <Row>
          <Col md={6}>
            <Image src={AboutUsBanner} smallScreen={smallScreen} />
          </Col>
          <Col md={6}>
            <TextContainer smallScreen={smallScreen}>
              <SubText smallScreen={smallScreen}>
                Vision
              </SubText>
              <MainText smallScreen={smallScreen}>
                To be the most trusted partner in digital transformation
              </MainText>
              <SubText smallScreen={smallScreen}>
                Mission
              </SubText>
              <MainText smallScreen={smallScreen}>
                Helping business to accelerate with technology for better and sustainable future
              </MainText>
            </TextContainer>
          </Col>
        </Row>
      </ContentContainer>
      <DescriptionContainer smallScreen={smallScreen}>
        <TitleText smallScreen={smallScreen}>
          You dream it, <br />
          we build and make it happen.
        </TitleText>

        <BodyText smallScreen={smallScreen}>
          Founded in 2018, Cloudxier is a tech and software development company based in Jakarta, Indonesia. Offering end-to-end technology consulting services from Product ideation, Cloud development to DevOps engineering we help our clients drive growth.
          <br />
          <br />
          We believe that in today's digital world, businesses need to harness technology and innovation quickly to deliver valuable impact every step of the way. That’s where we come in, our team of strategists and problem-solvers can help organizations to execute your digital transformation journeys.
        </BodyText>
      </DescriptionContainer>

    </MainContainer>
  )

}

export default OurStory

const MainContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const ContentContainer = styled.div`
  margin: ${({ smallScreen }) => smallScreen ? '12px 0 22px 0' : '26px 0 50px 0'};
  background: #F3F3F3;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '30px' : '0'}
`
const TextContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '22px 10vw' : '64px 180px 56px 80px'}
`
const MainText = styled.div`
  margin-top: ${({ smallScreen }) => smallScreen ? '8px' : '16px'};
  margin-bottom: ${({ smallScreen }) => smallScreen ? '16px' : '48px'};
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : "30px"};
  color: black;
  font-weight: 700;
  text-align: ${({ smallScreen }) => smallScreen ? 'center' : "left"};
`
const SubText = styled.div`
  color: #131A91;
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : "40px"};
  font-weight: bold;
  text-align: ${({ smallScreen }) => smallScreen ? 'center' : "left"};
`

const DescriptionContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '20px 10vw' : '112px 150px 90px 150px'}
`

const TitleText = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : "50px"};
  color: #336AFE;
  margin-bottom: ${({ smallScreen }) => smallScreen ? "22px" : "60px"};
  line-height:  ${({ smallScreen }) => smallScreen ? "unset" : "60px"};
  font-weight: 700;
`

const BodyText = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.bodySm : "30px"};
  line-height:  ${({ smallScreen }) => smallScreen ? "unset" : "60px"};
  font-weight: 500;
  margin-right: ${({ smallScreen }) => smallScreen ? "10vw" : "178px"};
`