// React
import { useEffect } from 'react'

// Styled components
import styled from 'styled-components'

// React router dom
import { useLocation, useHistory } from 'react-router-dom'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Images
import ProjectBanner from '../assets/images/projects/shooper/2-cloudxier-shooper-highlight03.jpg'

// Components
import HeadMeta from '../components/HeadMeta'
import ActionBanner from '../components/banners/ActionBanner'
import ProjectHeading from '../components/headings/ProjectHeading'
import ProjectDetailsColumn from '../components/ProjectDetailsColumn'
import SquareButton from '../components/buttons/SquareButton'

// React-bootstrap
import Container from 'react-bootstrap/Container'

// Helpers
import fontSize from '../helpers/fontSize'

// React-redux
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  projects: state.projects.projects
})

function ProjectDetails ({ projects }) {

  const query = new URLSearchParams(useLocation().search)

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  const history = useHistory()

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    // TODO: get project by ID using query here
    console.log(query.get("id"))
  }, [])

  return (
    <div>
      <HeadMeta 
        title='Cloudxier - Our Projects'
        description="Projects created by Cloudxier"
        siteName="Cloudxier"
        url={`https://www.cloudxier.com/projects/details?id=${query.get('id')}`}
        image={ProjectBanner} />
      {
        projects.map((project) => {
          if (project.id.toString() === query.get("id")) {
            return (
              <div style={{overflow: 'hidden', paddingTop: smallScreen ? '130px' : '150px'}}>
                <Container>
                  <ProjectHeading
                    title={project.category}
                    subtitle={project.title}
                    subtitleColor="#131A91" />
                </Container>
                {
                  project.features.map((feature, index) => {
                    return (
                      <ProjectDetailsColumn
                        isMediaLeft={index % 2 ? false : true}
                        imageUrl={feature.image}
                        text={feature.text}
                        textSize={
                          feature.isHeading ?
                            smallScreen ? 
                              fontSize.heading2Sm 
                            : 
                              fontSize.heading2Lg
                          :
                              smallScreen ? 
                                fontSize.bodySm 
                            : 
                                fontSize.bodyLg
                        } />
                    )
                  })
                }
                <ActionButtonContainer smallScreen={smallScreen}>
                  <SquareButton
                    action={() => history.push('/projects')}
                    text='See other projects'
                    textSize={smallScreen ? fontSize.bodySm : fontSize.bodyLg}
                    textColor='#131A91'
                    hoverTextColor='white'
                    backgroundColor='transparent'
                    hoverBgColor='#131A91'
                    width={smallScreen ? '200px' : '250px'}
                    border='1px solid #131A91' />
                </ActionButtonContainer>
                <ActionBanner
                  action={() => history.push('/contact-us')}
                  text='Ready to accelerate digital innovation in your business?'
                  actionText='Request a quote' />
              </div>
            )
          }
        })
      }
    </div>
  )

}

export default connect(mapStateToProps, null)(ProjectDetails)

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ smallScreen }) => smallScreen ? '10px 0 30px 0' : '30px 0 50px 0'}
`
