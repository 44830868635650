// React
import { useEffect } from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'

// Components
import HeadMeta from '../components/HeadMeta'
import HomeMainBanner from '../components/banners/HomeMainBanner'
import SectionHeading from '../components/headings/SectionHeading'
import FeatureTile from '../components/FeatureTile'
import FeatureColumn from '../components/FeatureColumn'
import FeatureCard from '../components/FeatureCard'
import HomeTestimonials from '../components/HomeTestimonials'
import CaseStudies from '../components/CaseStudies'
import HomeClients from '../components/HomeClients'
import ActionBanner from '../components/banners/ActionBanner'
import OurFounders from '../components/OurFounders'

// Images
import WhyChooseUs1 from '../assets/images/landing_page/why_choose_us/Cloudxier-whychooseus-01.png'
import WhyChooseUs2 from '../assets/images/landing_page/why_choose_us/Cloudxier-whychooseus-02.png'
import WhyChooseUs3 from '../assets/images/landing_page/why_choose_us/Cloudxier-whychooseus-03.png'
import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'

// React responsive
import { useMediaQuery } from 'react-responsive'

function Home() {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="Cloudxier - Mobile App Development | Website | Software | IT Consulting Company Jakarta"
        description="Cloudxier - We provide IT solutions for your business"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/"
        image={CloudxierLogoSquare} />
      <HomeMainBanner />
      <Container style={{
        padding: smallScreen ? '30px 10vw 30px 10vw' : '50px 0 50px 0'
      }}>
        <SectionHeading
          title='What We Do'
          subtitle='Empowering business with technology innovation' />
        <FeatureTile />
        <SectionHeading
          title='Why Choose Cloudxier'
          subtitle='Solve your tech challenges with the right partner' />
        <FeatureColumn
          titleText="Adapting agililty to ensure success in project’s delivery"
          descriptionText="We understand business processes can change rapidly from time to time, your business may have many adjustments from the first moment you have a discussion with us. Don't worry about it, we use an agile delivery process where changes can be adjusted without having to wait for the development process to be fully completed."
          titleColor='#131A91'
          mediaUrl={WhyChooseUs1}
          textContainerPadding={0}
          mediaHeight={smallScreen ? '200px' : '400px'} />
        <FeatureColumn
          isMediaLeft={true}
          titleText='We match and collaborate based on the needs to align with your goals'
          descriptionText="Collaboration has become an absolute thing in carrying out the mission to convey the company values. While good synergy can be achieved through the right partner, we will try our best in discussing your business goals with our technology solutions."
          titleColor='#131A91'
          mediaUrl={WhyChooseUs2}
          mediaHeight={smallScreen ? '200px' : '400px'} />
        <FeatureColumn
          titleText='Lead by experienced team using proven development methodologies'
          descriptionText="With the rapid development of technology and various ways of implementation, using the right methodology will bring good impacts to business success. We always try to use the tested development methodology to minimize risk and increase the speed of product iterations."
          titleColor='#131A91'
          mediaUrl={WhyChooseUs3}
          mediaHeight={smallScreen ? '200px' : '400px'} />
      </Container>
      <FeatureCard />
      <HomeClients />
      <OurFounders />
      <HomeTestimonials />
      <CaseStudies />
      <ActionBanner
        action={() => window.open('https://wa.me/6281385898240')}
        text='Ready to accelerate digital innovation in your business?'
        actionText='Consult with us' />
    </div>
  )

}

export default Home