// Styled components
import styled from 'styled-components'

// Bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import SquareButton from '../buttons/SquareButton'

// Helpers
import fontSize from '../../helpers/fontSize'

function ActionBanner ({ text, action, actionText }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  return (
    <MainContainer smallScreen={smallScreen}>
      <InnerContainer smallScreen={smallScreen}>
        <Container style={{ padding: smallScreen ? '0 10vw' : '0'}}>
          <Text smallScreen={smallScreen}>
            {text}
          </Text>
          <ActionButtonContainer smallScreen={smallScreen}>
            <SquareButton
              text={actionText}
              action={action}
              textSize={smallScreen ? fontSize.bodySm : fontSize.bodyLg}
              textColor='#131A91'
              hoverTextColor='white'
              backgroundColor='white'
              hoverBgColor='#131A91'
              width={smallScreen ? '200px' : '200px'}
              border='1px solid #131A91' />
          </ActionButtonContainer>
        </Container>
      </InnerContainer>
    </MainContainer>
  )

}

export default ActionBanner

const MainContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '0 10vw' : '0'};
  background-color: #4C39FF;
  display: flex;
  justify-content: center;
  align-items: center;
`
const InnerContainer = styled.div`
  width: ${({ smallScreen }) => smallScreen ? '100%' : '700px'};
  padding: ${({ smallScreen }) => smallScreen ? '30px 0' : '50px 0'};
`
const Text = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : fontSize.heading2Lg};
  color: white;
  font-weight: 500;
  text-align: center;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '30px' : '50px'};
`
const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center
`