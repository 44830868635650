// Styled components
import styled from 'styled-components'

// Bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Images
import CloudxierLogo from '../assets/images/logo/cloudxier_logo_dark.png'
import Facebook from '../assets/images/icons/facebook_dark.png'
import Instagram from '../assets/images/icons/instagram_dark.png'
import Linkedin from '../assets/images/icons/linkedin_dark.png'

// Helpers
import CurrentYear from "../helpers/CurrentYear"

function Footer() {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  return (
    <MainContainer smallScreen={smallScreen}>
      <Container style={{
        padding: smallScreen ? '0 10vw' : '0'
      }}>
        <FlexContainer smallScreen={smallScreen}>
          {
            smallScreen ?
              null
              :
              <LeftContainer>
                <BrandLogo src={CloudxierLogo} smallScreen={smallScreen} />
                <DescriptionText smallScreen={smallScreen}>
                  We provide end-to-end technology solutions for your business,<br />help you drive growth and positive impact in the future
                </DescriptionText>
              </LeftContainer>
          }
          <RightContainer>
            <LinkContainer smallScreen={smallScreen}>
              <LinkGroup smallScreen={smallScreen}>
                <NavItem href="/about" smallScreen={smallScreen}>
                  About Us
                </NavItem>
                <NavItem href="#" smallScreen={smallScreen}>
                  Expertise
                </NavItem>
                <NavItem href="/projects" smallScreen={smallScreen}>
                  Projects
                </NavItem>
                <NavItem target='_blank' href="https://www.sustainability.co.id/" smallScreen={smallScreen}>
                  Our Group
                </NavItem>
              </LinkGroup>
              <LinkGroup smallScreen={smallScreen}>
                <NavItem href="privacy-policy" smallScreen={smallScreen}>
                  Privacy Policy
                </NavItem>
                <NavItem href="contact-us" smallScreen={smallScreen}>
                  Contact
                </NavItem>
              </LinkGroup>
            </LinkContainer>
            <div class="clutch-widget" data-url="https://widget.clutch.co/" data-widget-type="13" data-height="50" data-nofollow="true" data-expandifr="true" data-clutchcompany-id="1811828"></div>
            <AcknowledgementContainer>
              <iframe width="100" height="100" src="https://shareables.clutch.co/share/badges/1811828/50687?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch It Services Company Ecommerce Indonesia"></iframe>
              {/* <div class="goodfirm-widget" data-widget-type="goodfirms-widget-t4" data-widget-pattern="simple-basic" data-height="120" data-company-id="130835"></div> */}
            </AcknowledgementContainer>
            <SocialMediaContainer smallScreen={smallScreen}>
              <SocmedText smallScreen={smallScreen}>
                Follow us on
              </SocmedText>
              <SocmedLogo>
                <SocmedIcon onClick={() => window.open('https://www.facebook.com/CloudxierIndonesia')} src={Facebook} smallScreen={smallScreen} />
                <SocmedIcon onClick={() => window.open('https://www.instagram.com/cloudxier/')} src={Instagram} smallScreen={smallScreen} />
                <SocmedIcon onClick={() => window.open('https://www.linkedin.com/company/cloudxier')} src={Linkedin} smallScreen={smallScreen} />
              </SocmedLogo>
            </SocialMediaContainer>
          </RightContainer>
        </FlexContainer>
        <Seperator />
        <FlexContainer smallScreen={smallScreen}>
          <SmallBottomText smallScreen={smallScreen}>
            © Cloudxier {<CurrentYear />}. All Rights Reserved.
          </SmallBottomText>
          <SmallBottomTextSecondary smallScreen={smallScreen}>
            PT Cendekia Edukasi Citra Tritunggal
          </SmallBottomTextSecondary>
        </FlexContainer>
      </Container>
    </MainContainer>
  )

}

export default Footer

const MainContainer = styled.div`
  background-color: #F3F3F3;
  padding: ${({ smallScreen }) => smallScreen ? '30px 0' : '50px 0'}
`
const FlexContainer = styled.div`
  display: ${({ smallScreen }) => smallScreen ? 'block' : 'flex'};
  justify-content: space-between;
  align-items: top;
  padding: ${({ smallScreen }) => smallScreen ? 'unset' : '0 30px;'}; 
`
const LeftContainer = styled.div`
  width: auto;
`
const BrandLogo = styled.img`
  width: 50%;
  max-width: 200px;
  height: auto;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '20px' : '40px'}
`
const DescriptionText = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? '8px' : '18px'};
  font-weight: 700;
  line-height: 30px;
`
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const LinkContainer = styled.div`
  display: flex;
  flex-direction: ${({ smallScreen }) => smallScreen ? 'column' : 'row'};
  gap: ${({ smallScreen }) => smallScreen ? 'unset' : '72px'};
`

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: ${({ smallScreen }) => smallScreen ? 'unset' : '180px'};
  margin-bottom: 12px;
`

const NavItem = styled.a`
  margin-bottom: ${({ smallScreen }) => smallScreen ? '6px' : '12px'};
  font-size: ${({ smallScreen }) => smallScreen ? '12px' : '16px'};
  line-height: ${({ smallScreen }) => smallScreen ? '16px' : '20px'};
  color: black;
  cursor: pointer;
`
const SocialMediaContainer = styled.div`
  margin-bottom: ${({ smallScreen }) => smallScreen ? '20px' : '40px'}
`
const SocmedText = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? '12px' : '16px'};
  font-weight: bold;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '8px' : '16px'};
  line-height: ${({ smallScreen }) => smallScreen ? '16px' : '20px'};
`
const SocmedLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const SocmedIcon = styled.img`
  width: ${({ smallScreen }) => smallScreen ? '12px' : '15px'};
  height: ${({ smallScreen }) => smallScreen ? '12px' : '20px'};
  margin-right: ${({ smallScreen }) => smallScreen ? '10px' : '30px'};
  object-fit: contain;
  cursor: pointer;
`
const Seperator = styled.div`
  height: 1px;
  width: 100%;
  background-color: black;
  margin-bottom: 20px;
`
const SmallBottomText = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? '10px' : '20px'};
  line-height: 20px;
`

const SmallBottomTextSecondary = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? '9px' : '18px'};
  line-height: 20px;
`

const AcknowledgementContainer = styled.div`
  padding-bottom: ${({ smallScreen }) => smallScreen ? '0' : '50px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%
`