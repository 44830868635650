import { useEffect, useState } from 'react'

// React router dom
import { Link, useLocation, useHistory } from "react-router-dom";

// Styled components
import styled, { keyframes, css } from 'styled-components'

// Bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import MediaQuery, { useMediaQuery } from 'react-responsive'

// Images
import CloudxierLogo from '../assets/images/logo/cloudxier_logo.png'
import CloudxierLogoDark from '../assets/images/logo/cloudxier_logo_dark.png'
import MagnifyIcon from '../assets/images/icons/magnify_icon.png'
import MagnifyIconDark from '../assets/images/icons/magnify_icon_dark.png'
import SearchArrowIcon from '../assets/images/icons/search_icon_light.png'

// Components
import RoundButton from './buttons/RoundButton'
import HeaderDropdown from './dropdowns/HeaderDropdown'

// Hamburger react
import { Squash as Hamburger } from 'hamburger-react'

function Header({ toggleCollapsableMenu, isOpenMenu }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [windowScrollPercentage, setWindowScrollPercentage] = useState(0)

  const [animateHeader, setAnimateHeader] = useState(false)

  const [animateSearchBar, setAnimateSearchBar] = useState(false)

  const [searchInput, setSearchInput] = useState('')

  const [expertiseMenuSelected, setExpertiseMenuSelected] = useState(false)

  const screenLocation = useLocation()

  const history = useHistory()

  // Play header transition on page load
  useEffect(() => {
    setAnimateHeader(true)
  }, [])

  // Listen on keydown effect on load
  useEffect(() => {
    window.addEventListener('keydown', handleKeydown)
    return function cleanUp() {
      window.removeEventListener('keydown', handleKeydown)
    }
  })

  // Listen on window scroll on load
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return function cleanUp() {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  // Handle keydown function
  function handleKeydown({ keyCode }) {
    switch (keyCode) {
      case 27:
        setSearchInput('')
        setAnimateSearchBar(false)
      default:
        return
    }
  }

  // Change header background color on scroll
  function handleScroll() {
    let scrollPercentage = window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
    setWindowScrollPercentage(scrollPercentage)
  }

  // Change dropdown text color on specific location
  function changeDropdownTxtColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return 'white'
      case '/expertise':
        return 'white'
      case '/projects':
        return 'white'
      case '/projects/details':
        return 'white'
      case '/contact-us':
        return 'white'
      case '/privacy-policy':
        return 'black'
      case '/':
        return 'black'
      default:
        return 'black'
    }
  }

  // Change text color on specific location
  function changeTextColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return 'black'
      case '/expertise':
        return 'black'
      case '/projects':
        return 'black'
      case '/projects/details':
        return 'black'
      case '/contact-us':
        return 'black'
      case '/privacy-policy':
        return 'black'
      case '/':
        return 'white'
      default:
        return 'white'
    }
  }

  // Change header logo on specific location
  function changeHeaderLogo() {
    switch (screenLocation.pathname) {
      case '/about':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/expertise':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/projects':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/projects/details':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/contact-us':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/privacy-policy':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/':
        return CloudxierLogo
      default:
        return CloudxierLogo
    }
  }

  // Change magnifier icon on specific location
  function changeMagnifierIcon() {
    switch (screenLocation.pathname) {
      case '/about':
        return MagnifyIconDark
      case '/expertise':
        return MagnifyIconDark
      case '/projects':
        return MagnifyIconDark
      case '/projects/details':
        return MagnifyIconDark
      case '/contact-us':
        return MagnifyIconDark
      case '/':
        return MagnifyIcon
      default:
        return MagnifyIcon
    }
  }

  // Change dropdown background color on specific location
  function changeDropdownBgColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return '#4C39FF'
      case '/expertise':
        return '#4C39FF'
      case '/projects':
        return '#4C39FF'
      case '/projects/details':
        return '#4C39FF'
      case '/contact-us':
        return '#4C39FF'
      case '/privacy-policy':
        return '#4C39FF'
      case '/':
        return 'white'
      default:
        return 'white'
    }
  }

  // Change header button background color on specific location
  function changeHeaderBtnBgColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return '#4C39FF'
      case '/expertise':
        return '#4C39FF'
      case '/projects':
        return '#4C39FF'
      case '/projects/details':
        return '#4C39FF'
      case '/contact-us':
        return '#4C39FF'
      case '/privacy-policy':
        return '#4C39FF'
      case '/':
        return 'transparent'
      default:
        return 'transparent'
    }
  }

  // Change background color based on specific location
  function changeBackgroundColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return 'white'
      case '/expertise':
        return 'white'
      case '/projects':
        return 'white'
      case '/projects/details':
        return 'white'
      case '/contact-us':
        return 'white'
      case '/privacy-policy':
        return 'white'
      case '/':
        return 'transparent'
      default:
        return 'transparent'
    }
  }

  // Stop header scroll animation on specific location
  function stopScrollAnimation() {
    switch (screenLocation.pathname) {
      case '/about':
        return 'none'
      case '/expertise':
        return 'none'
      case '/projects':
        return 'none'
      case '/projects/details':
        return 'none'
      case '/contact-us':
        return 'none'
      case '/privacy-policy':
        return 'none'
      case '/':
        return css`${changeHeaderColor} 1s linear`
      default:
        return css`${changeHeaderColor} 1s linear`
    }
  }

  // Change hamburger icon color on specific location
  function changeHamburgerColor() {
    switch (screenLocation.pathname) {
      case '/about':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/expertise':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/projects':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/projects/details':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/contact-us':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/privacy-policy':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/':
        return 'white'
      default:
        return 'white'
    }
  }

  return (
    <HeaderContainer
      animateHeader={animateHeader}
      windowScrollPercentage={windowScrollPercentage}
      stopScrollAnimation={stopScrollAnimation}
      changeBackgroundColor={changeBackgroundColor}
      isOpenMenu={isOpenMenu}>
      <Container style={{ padding: smallScreen ? '0 5vw' : 0 }}>
        <FlexContainer>
          <Link to="/" onClick={() => toggleCollapsableMenu(false)}>
            <LogoContainer smallScreen={smallScreen}>
              <Logo src={changeHeaderLogo()} />
            </LogoContainer>
          </Link>
          <NavbarContainer>
            {/* Render full width navigations on large screen */}
            <MediaQuery minWidth={1024}>
              <LinkContainer>
                <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor}>
                  <Link to="/about" style={{ color: changeTextColor() }}>
                    About
                  </Link>
                </NavItem>
              </LinkContainer>
              <NavItem
                onMouseEnter={() => setExpertiseMenuSelected(true)}
                onMouseLeave={() => setExpertiseMenuSelected(false)}
                animateSearchBar={animateSearchBar}
                changeTextColor={changeTextColor}>
                <LinkContainer style={{ cursor: 'pointer' }}>
                  Expertise
                </LinkContainer>
                <HeaderDropdown
                  isOpen={expertiseMenuSelected}
                  closeDropdown={() => setExpertiseMenuSelected(false)}
                  backgroundColor={changeDropdownBgColor()}
                  textColor={changeDropdownTxtColor()}
                  list={
                    [
                      {
                        title: "Mobile Application Development",
                        path: "/expertise?id=mobile-application"
                      },
                      {
                        title: "Website Development",
                        path: "/expertise?id=website-development"
                      },
                      {
                        title: "Digital Product Experience and Design",
                        path: "/expertise?id=digital-product"
                      },
                      {
                        title: "Enterprise Software Development",
                        path: "/expertise?id=enterprise-software"
                      },
                      {
                        title: "API Creation and Management",
                        path: "/expertise?id=api-management"
                      },
                      {
                        title: "Tech Resource Support",
                        path: "/expertise?id=tech-resource-support"
                      }
                    ]
                  } />
              </NavItem>
              <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor}>
                <LinkContainer>
                  <Link to="/projects" style={{ color: changeTextColor() }}>
                    Projects
                  </Link>
                </LinkContainer>
              </NavItem>
              <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor}>
                <LinkContainer>
                  <a href='https://www.sustainability.co.id/' target='_blank' style={{ color: changeTextColor() }}> Our group</a>
                </LinkContainer>
              </NavItem>
              <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor}>
                <LinkContainer>
                  <Link to="/contact-us" style={{ color: changeTextColor() }}>
                    Contact
                  </Link>
                </LinkContainer>
              </NavItem>
              <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor} style={{ fontWeight: 'bold' }}>
                <RoundButton
                  action={() => window.open('https://wa.me/6281385898240')}
                  width='100%'
                  borderColor="white"
                  backgroundColor={changeHeaderBtnBgColor()}
                  hoverBgColor="white"
                  textColor="white"
                  hoverTextColor="#131A91"
                  radius='20px'
                  text='Consult with us' />
              </NavItem>
              {/*
                <MagnifyContainer animateSearchBar={animateSearchBar} onClick={() => setAnimateSearchBar(true)} changeTextColor={changeTextColor}>
                  <Magnifier src={changeMagnifierIcon()} />
                  <SearchInputContainer animateSearchBar={animateSearchBar}>
                    <SearchInput
                      placeholder='Search...' 
                      type='text' 
                      value={searchInput} 
                      onChange={({ target: { value }}) => setSearchInput(value)}
                      changeTextColor={changeTextColor} />
                  </SearchInputContainer>
                  <ArrowRightIcon src={SearchArrowIcon} animateSearchBar={animateSearchBar} />
                </MagnifyContainer>
              */}
            </MediaQuery>
            {/* Render burger menu on small screen */}
            <MediaQuery maxWidth={1023}>
              <HamburgerContainer>
                <Hamburger
                  toggled={isOpenMenu}
                  toggle={toggleCollapsableMenu}
                  size={25}
                  distance='lg'
                  rounded
                  color={changeHamburgerColor()} />
              </HamburgerContainer>
            </MediaQuery>
          </NavbarContainer>
        </FlexContainer>
      </Container>
    </HeaderContainer>
  )

}

export default Header

const changeHeaderColor = keyframes`
  to {
    background-color: #4C39FF
  }
`
const HeaderContainer = styled.div`
  opacity: ${({ animateHeader }) => animateHeader ? 1 : 0};
  position: fixed;
  right: 0;
  left: 0;
  background-color: ${({ isOpenMenu, changeBackgroundColor }) => {
    if (isOpenMenu) {
      return '#4C39FF'
    } else {
      return changeBackgroundColor()
    }
  }};
  z-index: 5;
  transition: opacity 1s;
  transition-delay: .5s;
  animation: ${({ stopScrollAnimation }) => stopScrollAnimation()};
  animation-play-state: paused;
  animation-delay: ${({ windowScrollPercentage }) => `calc(${windowScrollPercentage} * -30s)`};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`
const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center
`
const LogoContainer = styled.div`
  height: auto;
  width: ${({ smallScreen }) => smallScreen ? '160px' : '200px'}
`
const Logo = styled.img`  
  width: 100%;
  height: auto;
  object-fit: contain
`
const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center
`
const NavItem = styled.div`
  opacity: ${({ animateSearchBar }) => animateSearchBar ? 0 : 1};
  color: ${({ changeTextColor }) => changeTextColor()};
  padding: 10px;
  font-size: 18px;
  transition: opacity .2s
`
const LinkContainer = styled.div`
  margin: 20px 0
`
const MagnifyContainer = styled.div`
  width: ${({ animateSearchBar }) => animateSearchBar ? '210px' : '0'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ changeTextColor }) => changeTextColor()};
  margin: 0 10px 0 10px;
  font-size: 15px;
  transition: width .2s;
  transition-delay: .2s;
  border-bottom: ${({ changeTextColor }) => '1px solid ' + changeTextColor()}
`
const Magnifier = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer
`
const SearchInputContainer = styled.div`
  opacity: ${({ animateSearchBar }) => animateSearchBar ? 1 : 0};
  transition: 0;
  transition-delay: .3s;
  padding-bottom: 2px
`
const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  color: ${({ changeTextColor }) => changeTextColor()};
`
const ArrowRightIcon = styled.img`
  opacity: ${({ animateSearchBar }) => animateSearchBar ? 1 : 0};
  width: 12px;
  height: 12px;
  cursor: pointer;
  transition: opacity .2s
`
const HamburgerContainer = styled.div`
  padding-top: 5px
`