// React
import { useState } from 'react'

// Styled components
import styled from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// React responsive
import { useMediaQuery } from 'react-responsive'

function ProjectDetailsColumn ({ isMediaLeft, text, imageUrl, textSize }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  const [isVisibleColumn, setVisibleColumn] = useState(false)

  if (isMediaLeft) {
    return (
      <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
        <ContentContainer isVisibleColumn={isVisibleColumn} smallScreen={smallScreen}>
          <ImageContainer smallScreen={smallScreen}>
            <Image src={imageUrl} />
          </ImageContainer>
          <DescriptionContainer smallScreen={smallScreen}>
            <Text textSize={textSize}>
              {text}
            </Text>
          </DescriptionContainer>
        </ContentContainer>
      </VisibilitySensor>
    )
  } else {
    return (
      <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
        <ContentContainer isVisibleColumn={isVisibleColumn} smallScreen={smallScreen}>
          <DescriptionContainer smallScreen={smallScreen}>
            <Text textSize={textSize}>
              {text}
            </Text>
          </DescriptionContainer>
          <ImageContainer smallScreen={smallScreen}>
            <Image src={imageUrl} />
          </ImageContainer>
        </ContentContainer>
      </VisibilitySensor>
    )
  }

}

export default ProjectDetailsColumn

const ContentContainer = styled.div`
  display: ${({ smallScreen }) => smallScreen ? 'block' : 'flex'};
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '20px' : '50px'};
  opacity: ${({ isVisibleColumn }) => isVisibleColumn ? 1 : 0};
  transition: opacity 1s
`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover
`
const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ smallScreen }) => smallScreen ? '5vw' : '0 8vw'};
  text-align: left
`
const Text = styled.div`
  font-size: ${({ textSize }) => textSize};
  color: black
`